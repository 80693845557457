<template>
  <el-drawer
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    :title="title"
    size="800px"
    class="detail-style"
  >
   <el-tabs class="details" v-model="activeName" @tab-click="handleClick" style="padding:0 10px">
<!--    <el-tab-pane label="课消明细" name="spends">-->
<!--      <StudentCourseLogList :user_id="user_id" :courseId="courseId"/>-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="课节明细" name="details">
      <StudentCourseDetail :user_id="user_id" :courseId="courseId"/>
    </el-tab-pane>
  </el-tabs>
  </el-drawer>
</template>
<script>
import StudentCourseLogList from "../StudentCourseLogList";
import StudentCourseDetail from "../StudentCourseDetail";
export default {
  components: { StudentCourseLogList,StudentCourseDetail },
  props: {
    title: {
      type: String,
      default: () => "详情",
    },
    user_id: {
      type: String,
      default: () => "",
    },
    courseId: {
      type: String,
      default: () => "",
    },
  },
  mounted(){
  },
  data() {
    return {
      activeName: 'details',
      drawer: false,
      direction: "rtl",
    };
  },
  methods: {
    /** 关闭抽屉 */
    handleClose() {
      this.drawer = false;
      this.$parent.searchForms = {};
    },
    /** tab 切换 */
    handleClick(val){
      // console.log(val);
    }
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
/deep/ .el-drawer__header{
  margin-bottom: 10px;
  padding:10px;
  border-bottom: 1px solid #eee;
}
.detail-style{
  .el-tabs__item.is-top.is-active{
  }
}
</style>
