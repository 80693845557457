<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>调班日期</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="调班日期：" prop="date">
        <el-date-picker
          v-model="formData.date"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <el-row slot="footer">
      <el-button type="primary" @click="confirm" class="dialog-btn1">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn1">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { changeClass } from '@/api/merchantClass'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    user_id: {
      default: '',
      type: String
    },
    classId: {
      default: '',
      type: String
    },
    classIdTo: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      formData: {},
      rules: {
        date: [{ required: true, message: '日期不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    openDialog () {
    },
    close () {
      this.$emit('close')
    },

    confirm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (Number(new Date(this.formData.date).getTime()) < Number(new Date().getTime())) {
            window.$msg('调班日期不能小于当前日期', 2)
          } else {
            const classId = this.$route.query.classID || this.classId;
            console.log(this.$route.query.classID , this.classId);
            changeClass({
              classIdFrom: classId, // 原班级ID
              classIdTo: this.classIdTo, // 新班级ID
              studentId: this.user_id, // 学生ID
              date: this.formData.date
            }).then(res => {
              if (res.state === 'success') {
                window.$msg('调班成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
  .dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 220px;
    margin-right: 38px;
  }
}
</style>
