<template>
  <el-dialog :visible.sync="dialogVisible" width="60%">
    <div slot="title" class="dia-tit">
      <i></i>
      <span>{{ title }}</span>
    </div>
    <el-form
      :model="formInfo"
      :rules="rules"
      :inline="false"
      ref="ruleForm"
      label-width="80px"
      class="demo-form-inline"
    >
      <el-form-item label="课程" prop="courseId">
        <el-select
          style="width: 100%"
          @focus="(e) => getCourseList(e.target.value)"
          @input.native="(e) => getCourseList(e.target.value)"
          @change="changeCourse"
          filterable
          clearable
          :loading="false"
          v-model="formInfo.courseId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in courseOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学员" prop="userIds">
        <el-row>
          <el-col :span="18">
            <el-select
              :disabled="!formInfo.courseId"
              style="width: 100%"
              @focus="(e) => getStudentList(e.target.value)"
              @input.native="(e) => getStudentList(e.target.value)"
              filterable
              clearable
              multiple
              :loading="false"
              v-model="formInfo.userIds"
              placeholder="请选择"
            >
              <el-option
                v-for="item in userOptions"
                :key="item.value"
                :label="item.label + '_' + item.tel"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; padding-left: 10px">{{
                  item.tel
                }}</span>
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button
              style="margin-left: 10px"
              type="primary"
              @click="$emit('openAddModal')"
              >创建新学员</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="班级" prop="classId">
        <el-select
          :disabled="!formInfo.courseId && !formInfo.userIds"
          style="width: 100%"
          @focus="(e) => getClassList(e.target.value)"
          @input.native="(e) => getClassList(e.target.value)"
          filterable
          clearable
          :loading="false"
          v-model="formInfo.classId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span style="float: left">
              {{ item.label }}
            </span>
            <span style="float: right; padding-left: 10px">
              {{ item.type }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button
        type="primary"
        @click="submitForm('ruleForm')"
        class="dialog-btn1"
      >
        确 定
      </el-button>
      <el-button @click="dialogVisible = false" type="info" class="dialog-btn1">
        取 消
      </el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import {
  getListCourseOnly as getCourseList,
  setCourseMember,
  getStudentListForCourse,
} from "@/api/businesscourse/course";
import { getClassAll as getClassList } from "@/api/merchantClass";
export default {
  props: {
    title: { type: String, default: "" },
    submit: {
      type: Function,
    },
  },
  data() {
    return {
      dialogVisible: false,
      userOptions: [],
      courseOptions: [],
      classOptions: [],
      courseName: "",
      formInfo: {
        courseId: "",
        userIds: "",
        classId: "",
      },
      rules: {
        userIds: [{ required: true, message: "请选择学员", trigger: "blur" }],
        courseId: [{ required: true, message: "请选择课程", trigger: "blur" }],
        classId: [{ required: false, message: "请选择班级", trigger: "blur" }],
      },
    };
  },
  methods: {
    /** 报名提交 */
    async submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          setCourseMember({
            courseId: this.formInfo.courseId,
            classId: this.formInfo.classId,
            userIds: this.formInfo.userIds.join(","),
          }).then((res) => {
            if (res.state == "success") {
              this.$message.success("添加成功");
              this.$parent.getListHandler();
              this.dialogVisible = false;
            }
          });
        }
      });
    },
    /** 获取未报课程下学员 */
    getStudentList(val) {
      if (!this.formInfo.courseId) {
        this.$message.warning("请先选择课程");
        return;
      }
      getStudentListForCourse({
        courseId: this.formInfo.courseId,
        needCount: true,
        name: val,
        pageSize: 9999,
        pageNum: 1,
        courseIn: false,
      }).then((res) => {
        this.userOptions = res.body.list.map((i) => ({
          value: i.id,
          label: i.name,
          tel: i.tel,
        }));
      });
    },
    /** 获取课程 */
    getCourseList(val) {
      getCourseList({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        this.courseOptions = res.body.list.map((i) => ({
          value: i.id,
          label: i.title,
        }));
      });
    },
    /** 课程选择变动 */
    changeCourse(e) {
      this.courseName = this.courseOptions.find((i) => i.value === e).label;
    },
    /** 获取课程下班级 */
    getClassList(val) {
      getClassList({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
        className: val,
        courseId: this.formInfo.courseId,
        // "productName": null, // 所属收费课程的名称
        // "productId": null, // 所属收费课程的ID
      }).then((res) => {
        this.classOptions = res.body.list.map((i) => ({
          value: i.class_id,
          label: i.name,
          type: i.type,
        }));
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    initData() {
      this.resetForm("ruleForm");
      this.formInfo = {
        userIds: "",
        courseId: "",
        classId: "",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/dialog.scss";
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
.dialog-btn1 {
  width: 200px;
  height: 50px;
  margin-bottom: 38px;
  &:first-child {
    margin-left: 220px;
    margin-right: 38px;
  }
}
</style>