var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "detail-style",
      attrs: {
        visible: _vm.drawer,
        direction: _vm.direction,
        "before-close": _vm.handleClose,
        title: _vm.title,
        size: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticClass: "details",
          staticStyle: { padding: "0 10px" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "课节明细", name: "details" } },
            [
              _c("StudentCourseDetail", {
                attrs: { user_id: _vm.user_id, courseId: _vm.courseId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }