<template>
  <div>
    <el-card shadow="never" style='max-height:calc(100vh - 100px);overflow-y:auto'>
      <div v-if="false" slot="header" class="flex" style="align-items: center">
        <span>课消明细</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '课次'">
                  <span>
                    第{{scope.row.sort}}节
                  </span>
              </template>
              <template v-if="item.label === '状态'">
                  <span>
                    <el-tag v-if="scope.row.studentLessonStatus==='已上课'">
                      {{scope.row.studentLessonStatus}}
                    </el-tag>
                    <!-- <el-tag v-if="scope.row.status==='未上课'" type="info"> -->
                    <el-tag v-else type="info">
                      {{scope.row.studentLessonStatus}}
                    </el-tag>
                  </span>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        v-if="false"
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import StudentCourseLogList from "./StudentCourseLogList";
import { getStudentCourseUnitDetail } from "@/api/businesscourse/course";
import TextButton from "@/components/TextButton";

// 默认展示列

const baseColSetting = [
   {
    label: "课次",
    prop: "courseTimes",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课节名称",
    prop: "title",
    state: true,
    align: "center",
    width: "100",
  },

  {
    label: "状态",
    prop: "studentLessonStatus",
    state: true,
    align: "center",
    width: "100",
  },

];
export default {
  components: { StudentCourseLogList, TextButton },
  props: {
    user_id: { type: String, default: "" },
    courseId: { type: String, default: "" },
  },
  data() {
    return {
      tableKey: 1,
      drawer: false,
      direction: "btt",
      item: {},
      size: "small",
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  watch: {
    'user_id': {
      handler(val){
        if(val) this.getListHandler();
      },
      immediate: true,
      deep: true,
    },
    'courseId': {
      handler(val){
        if(val) this.getListHandler();
      },
      immediate: true,
      deep: true,
    },

  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  created(){
    this.getListHandler();
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getStudentCourseUnitDetail({
        studentId:  this.user_id,
        courseId:  this.courseId
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 50px;
}
.item-text1 {
  font-weight: bold;
}
@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
@import "../../../style/container";
</style>