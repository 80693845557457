<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>学员分班</span>
    </div>
    <el-row>
      <el-input
        v-model.trim="classNameValue"
        placeholder="请输入班级名称"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>

      <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="班级" align="center" prop="name"></el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <el-button @click="adjustTo(scope.row)" plain size="small" type="primary">加 入</el-button>
        </template>
      </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-row>
  </el-dialog>
</template>

<script>
import {getClassAll } from '@/api/merchantClass'
import adjustDate from '@/components/merchantClass/adjust-date'
import {addStudent } from '@/api/merchantClass'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    user_id: {
      default: '',
      type: String
    },
    courseId: {
      default: '',
      type: String
    }
  },
  components: { adjustDate },
  data () {
    return {
      classNameValue: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      adjustDialog: false,
      classIdTo: '',
      classIdCopy:'',
    }
  },
  created(){
    this.classIdCopy = this.$route.query.classID || this.classId;
  },
  methods: {
    openDialog () {
      this.getClassAll()
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassAll()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassAll()
    },
    search () {
      this.pagination.currentPage = 1
      this.getClassAll()
    },
    reset () {
      this.classNameValue = ''
    },


    // 获取所属收费课程的列表
    async getClassAll () {
      const res = await getClassAll({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        productId: this.$route.query.productId,
        className: this.classNameValue,
        isFinish: false,
        courseId: this.courseId,
        // exceptClassIds: [this.classIdCopy]
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 调入
    adjustTo (row) {
      addStudent ({
        classId: row.class_id,
        userIdSet: [this.user_id]
      }).then(res=>{
        if(res.state==='success'){
          this.$message.success("分班成功！");
          this.updateView();
        }
      }).catch(err=>{
          this.$message.warning("分班失败，请重试！");
      })
    },
    close () {
      this.$emit('close')
    },

    updateView () {
      this.close()
      this.$emit('updateView')
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../../style/dialog.scss"
</style>
